<template>

  <div>
    <topslogan/>
    <div class="top_head">
      <topnav :category_id="$route.query.choose" :mask=false></topnav>
    </div>
    <div class="certificatedata">
<!--      {{certificatedata}}-->
      <img
          slot="cover"
          :alt="certificatedata.title"
          :src="server_url + certificatedata.img_src"
      />
    </div>
    <bottom></bottom>
  </div>
</template>

<script>

import Config from "@/config";
import topnav from "@/views/topnav";
import topslogan from "@/views/topslogan";
import bottom from "@/views/bottom";
import Storage from "@/common/storage";

export default {
  components: {topslogan, topnav, bottom},
  name: "category_list",
  props: ['my_id', 'choose'],
  data() {
    return {

      server_url: Config.data.server_url,
      Certificateno: "",
      loading: false,
      language: localStorage.language,
      certificatedata: [],
      certificateteachdata: [],
      current: 1,

      pagination: {
        onChange: page => {
          console.log(page);
        },
        pageSize: 100,
      },
    }
  },
  created() {
    this.Certificateno = this.$route.query.Certificateno
    this.getcertificate(this.Certificateno)
  },
  methods: {
    goCertificate(Certificateno) {

      this.$router.push({
        path: '/Certificateinfo',
        query: {
          Certificateno: Certificateno,
          t: new Date().getTime()
        }
      });
    },
    async getcertificate(certificate_id) {
      console.log("获取证书列表")
      this.certificatedata = [];
      let now = Date.now();
      let result = await this.$get("/api/customer/certificate/customerGetInfo", {
        params: {
          uid: Storage.uid,
          token: Storage.token,
          certificate_id: certificate_id,
          t: now,
        }
      });

      if (result.status === true) {
        console.log("Customer", result.data)
        this.certificatedata = result.data
        this.loading = false;
      }

      this.loading = false;
    },
  }
}
</script>

<style lang="scss" scoped>
.top_head {
  max-width: 1920px !important;
  margin-left: auto;
  margin-right: auto;
  height: 112px;
  background-color: #002654;

}

.breadcrumb_bg {
  background: #F2F4F5;
  padding-top: 18px;
  height: 64px;
}

.breadcrumb {
  max-width: 1248px;
  margin-right: auto;
  margin-left: auto;

}

.title {
  color: #fff;
}

.active {
  color: #fff;
  background: #CE1126;
}

.certificatedata {
  height: 100%;

  max-width: 1248px;
  margin-right: auto;
  margin-left: auto;
  padding-top: 80px;
  padding-bottom: 80px;
  text-align: center;
  img{
    width: 800px;
    margin-right: auto;
    margin-left: auto;
  }



  .btn-red {
    color: #fff;
    background-color: #CE1126 !important;
    border-color: #CE1126 !important;
  }

  .articles_list {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 34px;
    background-color: #F2F4F5;
    border-radius: 8px;
  }

}
.certificatelist{
  display: flex;
  justify-content: flex-start;
  width: 100%;
  /deep/ .ant-card-cover{
    padding: 10px;
  }
  .certificateinfo{
    width: 30%;
    margin-right: 2%;
    margin-bottom:15px;
  }
}
</style>